import {HttpClient, HttpResponseMessage} from "aurelia-http-client";
import {RuntimeInfo} from "./RuntimeInfo";

export class NitTools {
    public static GetId(stringOrReference: string | any): string {
        if (!stringOrReference)
            return undefined;

        let val = "";
        if (typeof stringOrReference === "string")
            val = stringOrReference;
        else if (stringOrReference?.reference)
            val = stringOrReference.reference;

        if (val) {
            if (val.indexOf('/_history') > -1)
                val = val.split('/_history')[0];

            if (val.indexOf('/') > -1) {
                val = val.split('/')[1];
            }

            if (val.indexOf('?') > -1)
                val = val.split('?')[0];

            if (val.indexOf('#') > -1)
                val = val.split('#')[0];

            if (val.indexOf('|') > -1)
                val = val.split('|')[0];

            return String(val);
        }

        return undefined;
    }

    public static FlattenObject(obj: any, prefix: string = ''): { [key: string]: any } {
        const result: { [key: string]: any } = {};

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const newKey = prefix ? `${prefix}.${key}` : key;

                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    // Recursively flatten nested objects
                    Object.assign(result, this.FlattenObject(obj[key], newKey));
                } else {
                    // Assign non-object values to the result object
                    result[newKey] = obj[key];
                }
            }
        }

        return result;
    }

    /** fix for hanging on scrolling to top/bottom on ipad */
    public static fixScrollLock(selector: string) {
        if (RuntimeInfo.IsMobile)
            return;

        $(selector).on('scroll', function () {
            let scrollTimer;
            if ($(this).data("scroll-timer")) {
                scrollTimer = $(this).data("scroll-timer");
            }

            clearTimeout(scrollTimer);
            $(this).data("scroll-timer", setTimeout(() => {
                this.scrollTop = Math.max(1, Math.min(this.scrollTop, this.scrollHeight - this.clientHeight - 1));
            }, 300));
        });
    }

    /***
     * TypeScript equivalent to C# string.Format(). Takes a formatted string like "foo {0} stuff" and an object array to return the correct replacement
     * @param formattedString a formatted string like "foo {0} bar {1}"
     * @param objects the objects to fill into the formattedString
     */
    public static Format(formattedString : string, ...objects : any[]) : string
    {
        if (!objects || objects.length === 0) return formattedString;

        for (let i = 0; i < arguments.length - 1; i++) {
            const reg = new RegExp("\\{" + i + "\\}", "gm");
            formattedString = formattedString.replace(reg, arguments[i + 1]);
        }

        return formattedString;
    }

    public static FormatJSON(object: any, identWidth: number = 4): string {
        return JSON.stringify(object, undefined, identWidth);
    }

    public static Chunk(array: any[], size: number): any[] {
        const chunked_arr = [];
        let copied = [...array]; // ES6 destructuring
        const numOfChild = Math.ceil(copied.length / size); // Round up to the nearest integer
        for (let i = 0; i < numOfChild; i++) {
            chunked_arr.push(copied.splice(0, size));
        }

        return chunked_arr;
    }

    public static SvgFromString(svgSource: string) {
        let parser = new DOMParser();
        return <SVGSVGElement>(<unknown>parser.parseFromString(svgSource, "image/svg+xml").documentElement);
    }

    public static async LoadSvg(url: string): Promise<SVGSVGElement> {
        return new Promise<SVGSVGElement>((resolve, reject) => {
            new HttpClient().get(url)
                .then((result: HttpResponseMessage) => {
                    let svg = NitTools.SvgFromString(result.response);
                    resolve(svg);
                })
                .catch(error => {
                    console.warn(error);
                    reject("Could not load SVG from " + url);
                })
        });
    }

    public static SvgToBase64ImageSource(svg: SVGSVGElement): string {
        // get svg data
        if (!svg) return undefined;
        let xml = new XMLSerializer().serializeToString(svg);

        // make it base64
        let svg64 = btoa(xml);
        let b64Start = 'data:image/svg+xml;base64,';

        return b64Start + svg64;
    }

    public static SvgToImage(svg: SVGSVGElement): HTMLImageElement {
        if (!svg) return undefined;
        let img = document.createElement('img');

        // set it as the source of the img element
        img.src = NitTools.SvgToBase64ImageSource(svg);

        return img;
    }

    // Converts from degrees to radians.
    public static GradToRad(degrees): number {
        return degrees * Math.PI / 180;
    }

// Converts from radians to degrees.
    public static RadToGrad(radians): number {
        return radians * 180 / Math.PI;
    }

    private static handlePinch(event) {
        event.preventDefault();
        // special hack to prevent zoom-to-tabs gesture in safari
        document.body.style.setProperty('zoom', '0.999');
    }

    public static disablePinchZoom() {
        document.addEventListener('gesturestart', this.handlePinch);
        document.addEventListener('gesturechange', this.handlePinch);
        document.addEventListener('gestureend', this.handlePinch);
    }

    public static enablePinchZoom() {
        document.removeEventListener('gesturestart', this.handlePinch);
        document.removeEventListener('gesturechange', this.handlePinch);
        document.removeEventListener('gestureend', this.handlePinch);
    }

    public static releaseScrollLock(selector: string) {
        if (RuntimeInfo.IsMobile)
            return;

        $(selector).off("scroll");
    }

    public static hashCode(source: string): string {
        let hash: any = ""; // 0, i, chr;
        // let chr = '';
        if (source.length === 0) return hash;
        for (let i = 0; i < source.length; i++) {
            let chr = source.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }

        return hash;
    };

    public static UidName(): string {
        return "UID_" + this.Uid().replace(/-/g, "_");
    }

    public static GetUrlParams(url? : string): any {
        const regex = /(\?|\&)([^=]+)\=([^&#\/]+)/gm;
        const str = url||window.location.href; //`http://localhost:8080/?embedded=1&unlock=1&pass=123456/#/visitExtended/56343793/Mustermann/Max/debug2`;
        let m;
        let result = {};

        while ((m = regex.exec(str)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            if (m && m[2] && m[3]) {
                result[m[2]] = m[3];
            }
        }

        return result;
    }

    public static Uid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    public static ToArray(object: any): any[] {
        if (!object) return undefined;
        let result = Object.keys(object).map(function (key) {
            return {"key": key, "value": object[key]};
        });

        return result;
    }

    public static Clone(object: any): any {
        if (!object) return undefined;
        
        return JSON.parse(JSON.stringify(object));
    }

    public static ToString(obj: any, charCount?: number, fillChar?: string): string {
        let result = String(obj);

        if (typeof charCount !== "undefined" && !isNaN(charCount) && result.length < charCount) {
            if (typeof fillChar === "undefined") fillChar = typeof obj === "number" ? '0' : " ";
            while (result.length < charCount) {
                result = fillChar + result;
            }
        }

        return result;
    }

    public static IncludeTrailingSlash(url: string): string {
        if (!url.endsWith('/')) url += "/";
        return url;
    }

    public static ExcludeTrailingSlash(url: string): string {
        if (url.endsWith('/')) url = url.substr(0, url.length - 1);
        return url;
    }

    public static ExcludeLeadingSlash(url: string): string {
        if (url.startsWith('/')) url = url.substr(1);
        return url;
    }

    public static IncludeLeadingSlash(url: string): string {
        return '/' + this.ExcludeLeadingSlash(url);
    }

    public static ParseBool(value: any): boolean {
        if (typeof value === 'undefined') return false;
        else if (typeof value === "boolean") return value;

        let sVal = String(value);
        return sVal.toUpperCase() === "TRUE" || sVal === "1" || sVal.toUpperCase() === "YES" || sVal.toUpperCase() === "JA";
    }

    public static Distinct(a: any[]): any[] {
        const result = [];

        const tmp = []; // here we store the stringified Resources
        a.forEach(item => {
            // move the items as stringified resources into the temp array
            let s = JSON.stringify(item);
            if (tmp.indexOf(s) === -1) tmp.push(s);
        })

        // parse the type back into the resulting array
        tmp.forEach(s => result.push(JSON.parse(s)));

        return result;
    }

    public static GetAbsoluteElementTop(qItem: HTMLElement) {
        if (!qItem) return;
        let top = 0;
        let left = 0;
        let currentElement = qItem;

        while (currentElement) {
            top += currentElement.offsetTop;
            left += currentElement.offsetLeft;
            currentElement = currentElement.offsetParent as HTMLElement;

            // Check if the current element has the class .mf-questionnaires-parent
            if (currentElement && currentElement.classList.contains('mf-questionnaires-parent')) {
                break; // Stop the iteration when reaching this container
            }
        }

        // return { top, left };
        return top;
    }

    public static IsArray(a): boolean {
        if (!a || typeof a === "undefined") return false;
        return (!!a) && (a.constructor === Array);
    };

    public static trim = function (str) {
        return str.trim ? str.trim() : str.replace(/^\s+|\s+$/g, '');
    };

    public static get dates() {
        return DateUtils;
    }
}

export class DateUtils {
    public static isWeekend(date) {
        let day = date.getDay();
        return day === 0 || day === 6;
    }

    public static isLeapYear(year) {
        // solution by Matti Virkkunen: http://stackoverflow.com/a/4881951
        return year % 4 === 0 && year % 100 !== 0 || year % 400 === 0;
    }

    public static daysInMonth(year, month) {
        return [31, DateUtils.isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    }

    public static isDate(obj) {
        return (/Date/).test(Object.prototype.toString.call(obj)) && !isNaN(obj.getTime());
    }

    public static setToStartOfDay(date) {
        if (DateUtils.isDate(date)) date.setHours(0, 0, 0, 0);
    }

    public static compareDates(a, b) {
        // weak date comparison (use setToStartOfDay(date) to ensure correct result)
        return a.valueOf() === b.valueOf();
    }
}
