import {RuntimeInfo} from "./RuntimeInfo";

export default class SystemHeaders {
    static get systemBase() {
        if (!RuntimeInfo.SystemHeader)
            RuntimeInfo.SystemHeader = 'http://nursit-institute.com/fhir/';

        let url = RuntimeInfo.SystemHeader;
        if (!url.endsWith('/')) url += '/';

        return url;
    }

    static get vendorBase(): string {
        return "http://nursit-institute.com/";
    }

    /** Identifier for Marking-System */
    static get systemMarks(): string {
        return this.vendorBase + "marks";
    }

    /** Identifier for CareIt-RiskAnalysis */
    static get systemRiskAnalysis(): string {
        return this.vendorBase + "risk-analysis";
    }

    static get systemSites(): string {
        return this.systemBase + 'locationSites';
    }

    static get systemWards(): string {
        return this.systemBase + 'locationWard';
    }

    static get systemRooms(): string {
        return this.systemBase + 'locationRooms';
    }

    static get systemBeds(): string {
        return this.systemBase + 'locationBeds';
    }

    static get systemEncounterType(): string {
        return 'http://nursit-institute.com/fhir/StructureDefinition/encounterType';
    }

    static get systemHos(): string {
        return this.systemBase + 'organizationHospitals';
    }

    static get systemDep(): string {
        return this.systemBase + 'organizationDepartments';
    }

    static get systemObservation(): string {
        return this.systemBase + 'observationBedTotals';
    }

    static get systemVisit(): string {
        return this.systemBase + 'visitNumbers';
    }

    static get systemPatient(): string {
        return this.systemBase + 'patientNumbers';
    }
}
